'use client';

import * as React from 'react';
import { useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { CmsMenuItem } from '@/services/cms/models/CmsNavigationItem';
import { SmallMenuDrawContent } from './SmallMenuDrawContent';
import {CmsSiteConfig} from '@/services/cms/models/CmsSiteConfig';

interface SmallMenuProps {
    items: CmsMenuItem[];
    siteConfig: CmsSiteConfig;
}

export const SmallMenu: React.FC<SmallMenuProps> = ({ items,siteConfig }) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'mobile-menu',
    });

    const drawProps = bindMenu(popupState);
    const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
    const open = drawProps.open;
    const onClose = drawProps.onClose;
    useEffect(() => {
        if (!isXs && open) {
            onClose();
        }
    }, [isXs, open, onClose]);
    return (
        <Box sx={{ height: '100%', display: { xs: 'flex', sm: 'none' }, alignItems: 'center', ml: 'auto' }}>
            <IconButton {...bindToggle(popupState)}>
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor="right"
                sx={{ pointerEvents: 'none' }}
                componentsProps={{
                    backdrop: {
                        hidden: true,
                    },
                }}
                ModalProps={{
                    keepMounted: false,
                }}
                PaperProps={{
                    elevation: 0,
                    sx: (theme) => ({
                        top: theme.settings?.appBar.spacer(),
                        pointerEvents: 'all',
                        width: '100vw',
                        overflowx: 'hidden',
                        py: 2,
                    }),
                }}
                id={drawProps.id}
                open={open}
                onClose={onClose}
                onMouseLeave={drawProps.onMouseLeave}
            >
                <SmallMenuDrawContent items={items} siteConfig={siteConfig} />
            </Drawer>
        </Box>
    );
};
